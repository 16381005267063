<template>
  <div class="page-block">

    <i18n-selector />

    <span @click="logout" class="button logoutbutton w-inline-block underline-link"
      v-if="clientAuthType && clientAuthType !== 'SSO'">
      <img src="@/assets/images/logout-1.svg" loading="lazy" alt="" class="logouticon">
      <div>{{ $t('dashboardPage_logout') }}</div>
    </span>

    <span @click="goToMainMenu"
        class="button w-button backbutton">
      {{ $t('navigationMenu_goToBack') }}
    </span>

    <zhh-zedic-header :title="$t('hepProgram_voucherPage_title')" />

    <div class="page-block-item bodytext general-text text-justify">
      <p v-html="$t('hepProgram_voucherPage_pageDescription')"></p>
    </div>

    <div class="page-block-item bodytext general-text text-justify">
      <p v-html="$t('hepProgram_voucherPage_voucherInfo')"></p>
    </div>

    <spinning-loader v-show="showPageSpinningLoader" />

    <div class="page-container" v-show="!showPageSpinningLoader">

      <div class="page-block-item">
        <img class="voucher-img" style="width: 100%;" :src="voucherSource" alt="My Image" />
      </div>

      <div v-if="!!voucherSource" class="menu-container mt-20">
        <div >
          <span @click="downloadPdf" class="button w-button menu-button">
            {{ $t('hepProgram_voucherPage_savePhoto') }}
          </span>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import AuthApi from '@/api/auth.api';
import HepProgramApi from '@/api/hep-program.api';
import Utils from '@/utils/utils';

const CLIENT = Utils.getClient();

export default {
  name: 'HepProgram_VoucherPage',
  components: {},

  data() {
    return {
      loggedUser: undefined,
      clientAuthType: undefined,
      loadingPage: true,
      clientId: CLIENT,
      voucherSource: undefined,
    };
  },

  created() {
    if (!this.isHepProgram()) {
      this.$router.push({ name: 'AuthErrorPage' });
    } else {
      this.loadPage();
    }
  },

  methods: {
    isHepProgram() {
      return Utils.isHepProgramClient();
    },

    async loadPage() {
      try {
        const loggedUserResult = await AuthApi.getMe();
        this.loggedUser = loggedUserResult.data;

        const { auth } = this.loggedUser;
        this.clientAuthType = auth;

        const voucher = await HepProgramApi.getVoucher();

        this.voucherSource = `data:image/png;base64,${voucher.png}`;

        this.loadingPage = false;
      } catch (e) {
        this.showErroAlert({
          title: 'Oops!',
          text: this.$t('somethingWentWrong'),
        });
        this.loadingPage = false;
      }
    },

    downloadPdf() {
      const link = document.createElement('a');
      link.href = this.voucherSource;
      link.target = '_blank';
      link.download = 'zhh_walmart_voucher';

      // Simulate a click on the element <a>
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },

    goToMainMenu() {
      this.$router.push({ name: 'HepProgram_HomePage' });
    },

    showErroAlert(message) {
      const options = {
        ...message,
        // position: 'top-end',
      };
      return this.$swal(options);
    },

    async logout() {
      try {
        await AuthApi.logOut();
      } catch (e) {
        //
      }
    },
  },

  computed: {
    showPageSpinningLoader() {
      return this.loadingPage;
    },
  },
};
</script>
<style>
.voucher-img {
  background: grey;
  padding: 10px;
  border-radius: 5px;
}

</style>
<style scoped>
  .backbutton {
    position: absolute;
    left: 5%;
    margin-top: -25px;
    width: 100px;
  }

  .logoutbutton {
    width: 190px;
    color: #5031D0;
    text-decoration: none;
    position: absolute;
    right: 5%;
    margin-top: -25px;
    text-transform: uppercase;
  }

  .logouticon {
    width: 25px;
    margin-right: 12px;
  }

  .mt-20 {
    margin-top: 20px;
  }

  .menu-container {
    margin-bottom: 100px;
  }

  @media screen and (max-width: 479px) {
    .backbutton {
      position: relative;
      left: 0px;
      margin-top: -10px;
      margin-bottom: 20px;
      width: 100%;
    }

    .page-container {
      width: 100%;
    }

    .logoutbutton {
      position: relative;
      right: 0px;
      margin-top: -10px;
      margin-bottom: 20px;
      width: 100%;
    }
  }
</style>
